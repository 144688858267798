<template>
  <div class="fc-wrapper p-grid p-nogutter nested-grid" style="width: 100%">
    <div class="p-col-2 fc-groups">
      <div class="p-grid p-nogutter nested-grid p-ai-center vertical-container fc-groups__title">
        <div class="p-col-12 p-text-center p-text-bold">{{_t('label_Events')}}</div>
      </div>
      <div class="p-grid p-nogutter nested-grid fc-groups__list">
        <div
            class="fc-groups__list-item p-col-12 p-p-2"
            v-for="(event, eventIndex) in eventsInDay"
            :key="eventIndex"
        >
          {{ event.title }}
        </div>
      </div>
    </div>
    <div class="p-col-10 fc-month">
      <div class="p-col-12 fc-header p-p-2 p-text-center p-text-bold">
        {{ moment(date).format('DD') }} {{ _t(moment(date).format('MMMM').toUpperCase()) }} {{ moment(date).format('YYYY') }}
      </div>
      <div class="p-grid p-nogutter nested-grid" style="width: 100%">
        <div class="p-col-12">
          <div class="fc-header__weeks p-grid nested-grid p-nogutter">
            <div
              class="fc-header__week p-col p-text-center p-text-bold"
              v-for="(hour, hourIndex) in hoursInDay"
              :key="hourIndex"
              :data-hour="hour"
              :style="{
                // width: this.weeksInMonth.map(i => i.number).slice(1, this.weeksInMonth.map(i => i.number).length - 1).indexOf(week.number) !== -1 ? '100%' : '',
              }"
            >
              <div
                class="p-grid nested-grid p-nogutter"
                style="width: 100%"
              >
                <div
                  class="fc-header__weekday fc-header__cell p-col p-text-center p-py-2 p-text-bold"
                  :data-date="moment(date).format('YYYY-MM-DD')"
                  :data-hour="hour"
                  style="max-height: 33px; overflow: hidden;"
                >
                  <div class="box">{{ hour }}<br>{{ moment(date).hours(hour).format('A') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-col-12 fc-body" style="position: relative;">
          <div
              class="fc-days p-grid nested-grid p-nogutter"
              style="width: 100%;"
              v-for="(event, eventIndex) in eventsInDay"
              :key="eventIndex"
          >
            <template
                v-for="(hour, hourIndex) in hoursInDay"
                :key="hourIndex"
            >
              <div
                  class="fc-body__cell p-col"
                  :data-date="moment(date).format('YYYY-MM-DD')"
                  :data-hour="hour"
              >
                <span style="margin-left: 50%; border-style: dotted; border-width: 1px; border-color: lightgray; width: 1px; height: 100%; display: block;"></span>
              </div>
            </template>
            <template
              v-for="(time, timeIndex) in event.times"
              :key="timeIndex"
            >
              <div
                  class="fc-event p-p-2 p-col"
                  style="max-height: 33px;overflow: hidden;"
                  :style="{ backgroundColor: '#689F38' }"
                  :data-id="event.id"
                  :data-start="moment(time.start).format('YYYY-MM-DD HH:mm')"
                  :data-end="moment(time.end).format('YYYY-MM-DD HH:mm')"
                  v-tooltip.top="`<small><strong>${event.title}${time.holiday ? ' (' + time.holiday.title + ')' : ''}\n</strong>Start: ${moment(time.start).format('YYYY-MM-DD HH:mm')}\nEnd: ${moment(time.end).format('YYYY-MM-DD HH:mm')}</strong>`"
                  @click="$emit('clickEvent', $event)"
              >
                <!--              {{ event.title }}-->
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone';
import { ViewTypeEnum } from '@/components/CustomFullCalendar/enums/view-type.enum';
import { eventDrawDayMixin } from '@/components/CustomFullCalendar/mixins/event-draw.mixin';
import { commonMixin } from '@/components/CustomFullCalendar/mixins/common.mixin';

export default {
  name: 'timeline-day',
  mixins: [commonMixin, eventDrawDayMixin],
  data: () => ({
    viewType: ViewTypeEnum.DAY,
    dateTimeFormat: 'YYYY-MM-DDTHH:mm:ss',
    eventsInDay: [],
  }),
  created () {
    this.moment = moment;
    this.console = console;
    this.moment.locale('en', {
      week: {
        dow: 1,
      }
    });
    // document.addEventListener('load', this.eventsDayDraw);
  },
  mounted () {
    this.eventsDayDraw();
    document.addEventListener('resize', this.eventsDayDraw);
    this.calculateEvents();
  },
  beforeUnmount () {
    // document.removeEventListener('load', this.eventsDayDraw);
    document.removeEventListener('resize', this.eventsDayDraw);
  },
  unmounted () {
    // document.removeEventListener('load', this.eventsDayDraw);
    document.removeEventListener('resize', this.eventsDayDraw);
  },
  methods: {
    calculateEvents() {
      this.eventsInDay = JSON.parse(JSON.stringify(this.events)).filter(e => {
        e.times = e.times.filter(t => moment(t.end).isSameOrAfter(moment(this.dateStartDay).startOf('days'))
            && moment(t.start).isSameOrBefore(moment(this.dateEndDay).endOf('days')));
        return e;
      }).filter(e => e.times.length);
    }
  },
  computed: {
    dateStartDay() {
      return moment(this.date).startOf('days').format('YYYY-MM-DD');
    },
    dateEndDay() {
      return moment(this.date).endOf('days').format('YYYY-MM-DD');
    },
    hoursInDay() {
      let hours = [];
      for (let i = moment(this.dateStartDay).startOf('days'); moment(i).isSameOrBefore(moment(this.dateEndDay).endOf('days')); i.add(1, 'hours')) {
        hours.push(i.format('HH'));
      }
      return hours.sort((a,b) => a - b);
    },
    randomColor() {
      return "#" + ((1<<24)*Math.random() | 0).toString(16);
    },
  },
  watch: {
    events: function () {
      this.calculateEvents();
    },
    'options.dow': function () {

    },
  }
}
</script>

<style scoped>
:deep(.fc-body) {
  overflow: hidden;
}
:deep(.fc-header *),
:deep(.fc-header__cell *),
:deep(.fc-header__week *),
:deep(.fc-groups *)
{
  font-size: 12px;
}
:deep(.fc-header__month),
:deep(.fc-header__week-title),
:deep(.fc-header),
:deep(.fc-header__day),
:deep(.fc-body__cell),
:deep(.fc-groups__title),
/*:deep(.fc-groups__list),*/
:deep(.fc-header__cell),
:deep(.fc-groups__list-item) {
  border: 1px solid #c5c6c7;
}
:deep(.fc-groups__title) {
  min-height: 82px;
}
:deep(.fc-header__week-title) {
  max-height: 33px;
  overflow: hidden;
}
:deep(.fc-groups__list) {
  /*min-width: 200px;*/
  min-height: 33px;
}
:deep(.fc-groups__list-item) {
  min-height: 47px;
}
:deep(.fc-header__cell),
:deep(.fc-body__cell) {
  min-height: 47px;
}
:deep(.fc-event) {
  position: absolute;
}
:deep(.fc-body__cell) {
  transform: translateX(0px);
}
</style>
