import moment from 'moment-timezone';

/* TIMELINE */
export const eventDrawMixin = {
  methods: {
    eventsDraw() {
      const mainSelector = ':is(.TIMELINE-WEEK, .TIMELINE-MONTH) ';
      const calendars = document.querySelectorAll(mainSelector);
      calendars.forEach(calendar => {
        const cells = calendar.querySelectorAll('.fc-body__cell');
        if (cells.length) {
          const widthCell = cells[0].offsetWidth;
          const events = calendar.querySelectorAll('.fc-event');
          events.forEach(event => {
            const start = moment(event.getAttribute('data-start'), 'YYYY-MM-DD HH:mm');
            const end = moment(event.getAttribute('data-end'), 'YYYY-MM-DD HH:mm');
            // const offsetLeft = parseInt(moment(event.getAttribute('data-start'), 'YYYY-MM-DD HH:mm').format('D'))
            //   + parseInt(moment(event.getAttribute('data-start'), 'YYYY-MM-DD HH:mm').startOf('months').format('d')) - 2;
            // const left = widthCell * offsetLeft;
            const maxWidth = calendar.querySelector('.fc-days').offsetWidth;
            const left = calendar.querySelector(`.fc-body__cell[data-date="${moment(start).format('YYYY-MM-DD')}"]`)?.offsetLeft ?? 0;
            let width = calendar.querySelector(`.fc-body__cell[data-date="${moment(end).format('YYYY-MM-DD')}"]`)?.offsetLeft ?? maxWidth;
            const marginLeft = widthCell / 100 * ((parseInt(moment(start).format('HH'))+parseInt(moment(start).format('mm'))/60) / 0.24);
            const widthHours = widthCell / 100 * ((parseInt(moment(end).format('HH'))+parseInt(moment(end).format('mm'))/60) / 0.24);
            // const diff = moment(end).diff(start, 'days') < 1 ? 1 : moment(end).diff(start, 'days');
            width = width - left - marginLeft + widthHours;
            event.style.left = left + 'px';
            // event.style.right = right + 'px';
            event.style.width = width + 'px';
            event.style.marginLeft = marginLeft + 'px';
          });
        }
      });
    }
  },
}

/* TIMELINE DAY */
export const eventDrawDayMixin = {
  methods: {
    eventsDayDraw() {
      const mainSelector = ':is(.TIMELINE-DAY) ';
      const calendars = document.querySelectorAll(mainSelector);
      calendars.forEach(calendar => {
        const cells = calendar.querySelectorAll('.fc-body__cell');
        if (cells.length) {
          const widthCell = cells[0].offsetWidth;
          const events = calendar.querySelectorAll('.fc-event');
          events.forEach(event => {
            const start = moment(event.getAttribute('data-start'), 'YYYY-MM-DD HH:mm');
            const end = moment(event.getAttribute('data-end'), 'YYYY-MM-DD HH:mm');
            // const offsetLeft = parseInt(moment(event.getAttribute('data-start'), 'YYYY-MM-DD HH:mm').format('D'))
            //   + parseInt(moment(event.getAttribute('data-start'), 'YYYY-MM-DD HH:mm').startOf('months').format('d')) - 2;
            // const left = widthCell * offsetLeft;
            const maxWidth = calendar.querySelector('.fc-days').offsetWidth;
            const left = calendar.querySelector(`.fc-body__cell[data-date="${moment(start).format('YYYY-MM-DD')}"][data-hour="${moment(start).format('HH')}"]`)?.offsetLeft ?? 0;
            let width = calendar.querySelector(`.fc-body__cell[data-date="${moment(end).format('YYYY-MM-DD')}"][data-hour="${moment(end).format('HH')}"]`)?.offsetLeft ?? maxWidth;
            const marginLeft = widthCell / 100 * (
              (
                parseInt(moment(start).format('mm'))
              ) / 0.6
            );
            const widthMinutes = widthCell / 100 * (
              (
                parseInt(moment(end).format('mm'))
              ) / 0.6
            );
            // const diff = moment(end).diff(start, 'days') < 1 ? 1 : moment(end).diff(start, 'days');
            width = width - left - marginLeft + widthMinutes;
            event.style.left = left + 'px';
            // event.style.right = right + 'px';
            event.style.width = width + 'px';
            event.style.marginLeft = marginLeft + 'px';
          });
        }
      });
    }
  },
}

/* DAYGRID */
export const DayGridDrawMixin = {
  methods: {
    dayGridDraw() {
      const mainSelector = ':is(.DAYGRID-MONTH, .DAYGRID-YEAR) ';
      const calendars = document.querySelectorAll(mainSelector);
      calendars.forEach(calendar => {
        const cells = calendar.querySelectorAll('.fc-body__cell');
        if (cells.length) {
          const widthCell = cells[0].offsetWidth;
          const events = calendar.querySelectorAll('.fc-event');
          events.forEach(event => {
            const start = moment(event.getAttribute('data-start'), 'YYYY-MM-DD HH:mm');
            const end = moment(event.getAttribute('data-end'), 'YYYY-MM-DD HH:mm');
            // const offsetLeft = parseInt(moment(event.getAttribute('data-start'), 'YYYY-MM-DD HH:mm').format('D'))
            //   + parseInt(moment(event.getAttribute('data-start'), 'YYYY-MM-DD HH:mm').startOf('months').format('d')) - 2;
            // const left = widthCell * offsetLeft;
            const maxWidth = calendar.querySelector('.fc-days').offsetWidth;
            const left = calendar.querySelector(`.fc-body__cell[data-date="${moment(start).format('YYYY-MM-DD')}"]`)?.offsetLeft ?? 0;
            const top = calendar.querySelector(`.fc-body__cell[data-date="${moment(start).format('YYYY-MM-DD')}"]:not(.fc-prev-month):not(.fc-next-month)`)?.offsetTop ?? 0;
            let width = calendar.querySelector(`.fc-body__cell[data-date="${moment(end).format('YYYY-MM-DD')}"]`)?.offsetLeft ?? maxWidth;
            const marginLeft = widthCell / 100 * ((parseInt(moment(start).format('HH'))+parseInt(moment(start).format('mm'))/60) / 0.24);
            const widthHours = widthCell / 100 * ((parseInt(moment(end).format('HH'))+parseInt(moment(end).format('mm'))/60) / 0.24);
            // const diff = moment(end).diff(start, 'days') < 1 ? 1 : moment(end).diff(start, 'days');
            width = width - left - marginLeft + widthHours;
            event.style.left = left + 'px';
            event.style.top = top + 20 + 'px';
            // event.style.right = right + 'px';
            event.style.width = width + 'px';
            event.style.marginLeft = marginLeft + 'px';
          });
        }
      });
    }
  },
}
