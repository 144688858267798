<template>
  <div class="fc-wrapper p-grid p-nogutter nested-grid" style="width: 100%">
    <div class="p-col-1 fc-groups">
      <div class="p-grid p-nogutter nested-grid p-ai-center vertical-container fc-groups__title">
        <div class="p-col-12 p-text-center p-text-bold">{{ _t('label_Events') }}</div>
      </div>
      <div class="p-grid p-nogutter nested-grid fc-groups__list">
        <div
            class="fc-groups__list-item p-col-12 p-p-2 fs-11"
            v-for="(event, eventIndex) in eventsInMonth"
            :key="eventIndex"
        >
          {{ event.title }}
        </div>
      </div>
    </div>
    <div class="p-col-11 fc-month">
      <div class="p-grid p-nogutter nested-grid" style="width: 100%">
        <div class="p-col-12 fc-header p-p-2 p-text-center p-text-bold">
          {{ _t(moment(date).format('MMMM').toUpperCase()) }} {{ moment(date).format('YYYY') }}
        </div>
        <div class="p-col-12">
          <div class="fc-header__weeks p-grid nested-grid p-nogutter">
            <div
                class="fc-header__week p-col p-text-center p-text-bold"
                v-for="(week, weekIndex) in weeksInMonth"
                :key="weekIndex"
                :data-week="week.number"
                :style="{
                  // width: this.weeksInMonth.map(i => i.number).slice(1, this.weeksInMonth.map(i => i.number).length - 1).indexOf(week.number) !== -1 ? '100%' : '',
                }"
            >
              <div
                  class="p-grid nested-grid p-nogutter"
                  style="width: 100%"
              >
                <div
                    class="fc-header__week-title p-d-block p-col p-text-center p-text-bold p-p-2"
                    style="width: 100%"
                >
                  <div class="box">
                    {{ _t('label_week') }} {{ week.number }}
                  </div>
                </div>
              </div>
              <div
                  class="p-grid nested-grid p-nogutter"
                  style="width: 100%"
              >
                <template
                    v-for="(day, dayIndex) in week.dates"
                    :key="dayIndex"
                >
                  <div
                      class="fc-header__weekday fc-header__cell p-col p-text-center p-py-2 p-text-bold"
                      :data-date="moment(day).format('YYYY-MM-DD')"
                      :data-weekday="moment(day).format('dd')"
                      style="max-height: 33px; overflow: hidden;"
                      :class="{
                        'fc-current-month': moment(day).month() === moment(date).month(),
                        'fc-prev-month': moment(day).month() < moment(date).month(),
                        'fc-next-month': moment(day).month() > moment(date).month(),
                        'fc-today': moment(day).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'),
                        'fc-weekend': [6,7].includes(parseInt(moment(day).isoWeekday())),
                        'fc-holiday': holidays.find(i => moment(day).isBetween(moment(i.start).startOf('days'), moment(i.end).endOf('days'),undefined, []))
                      }"
                  >
                    <div class="box">{{ moment(day).format('DD') }}<br>{{ _t(weekdays[moment(day).format('d')]) }}</div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="p-col-12 fc-body" style="position: relative;">
          <div
              class="fc-days p-grid nested-grid p-nogutter"
              style="width: 100%;"
              v-for="(event, eventIndex) in eventsInMonth"
              :key="eventIndex"
          >
            <!--            <template-->
            <!--                v-for="(day, dayIndex) in Math.abs(moment(this.dateStartMonth).startOf('weeks').diff(moment(this.dateEndMonth).endOf('weeks'), 'days'))+1"-->
            <!--                :key="dayIndex"-->
            <!--            >-->
            <template
                v-for="(week, weekIndex) in weeksInMonth"
                :key="weekIndex"
            >
              <template
                  v-for="(day, dayIndex) in week.dates"
                  :key="dayIndex"
              >
                <div
                    class="fc-body__cell p-col"
                    :data-date="moment(day).format('YYYY-MM-DD')"
                    :class="{
                      'fc-current-month': moment(day).month() === moment(date).month(),
                      'fc-prev-month': moment(day).month() < moment(date).month(),
                      'fc-next-month': moment(day).month() > moment(date).month(),
                      'fc-today': moment(day).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD'),
                      'fc-weekend': [6,7].includes(parseInt(moment(day).isoWeekday())),
                      'fc-holiday': holidays.find(i => moment(day).isBetween(moment(i.start).startOf('days'), moment(i.end).endOf('days'),undefined, []))
                    }"
                >
                  <span
                      style="margin-left: 50%; border-style: dotted; border-width: 1px; border-color: lightgray; width: 1px; height: 100%; display: block;"></span>
                </div>
              </template>
            </template>
            <template
                v-for="(time, timeIndex) in event.times"
                :key="timeIndex"
            >
              <div
                  class="fc-event p-col"
                  style="max-height: 33px;overflow: hidden; min-height: 16px;"
                  :style="{ backgroundColor: '#689F38' }"
                  :data-id="event.id"
                  :data-start="moment(time.start).format('YYYY-MM-DD HH:mm')"
                  :data-end="moment(time.end).format('YYYY-MM-DD HH:mm')"
                  v-tooltip.top="`<small><strong>${event.title}${time.holiday ? ' (' + time.holiday.title + ')' : ''}\n</strong>Start: ${moment(time.start).format('YYYY-MM-DD HH:mm')}\nEnd: ${moment(time.end).format('YYYY-MM-DD HH:mm')}</small>`"
                  @click="$emit('clickEvent', $event)"
              >
                <!--              {{ event.title }}-->
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import * as _ from 'lodash';
import {ViewTypeEnum} from '@/components/CustomFullCalendar/enums/view-type.enum';
import {eventDrawMixin} from '@/components/CustomFullCalendar/mixins/event-draw.mixin';
import {commonMixin} from '@/components/CustomFullCalendar/mixins/common.mixin';

moment.updateLocale('en', {
  week: {
    dow: 1,
  }
});

export default {
  name: 'timeline-month',
  mixins: [commonMixin, eventDrawMixin],
  data: () => ({
    viewType: ViewTypeEnum.MONTH,
    dateTimeFormat: 'YYYY-MM-DDTHH:mm:ss',
    eventsInMonth: [],
    moment,
    weekdays: [
      'label_sunday_short',
      'label_monday_short',
      'label_tuesday_short',
      'label_wednesday_short',
      'label_thursday_short',
      'label_friday_short',
      'label_saturday_short',
    ],
  }),
  created() {
    this.console = console;
    this.moment.updateLocale('en', {
      week: {
        dow: 1,
      }
    });
    // document.addEventListener('load', this.eventsDraw);
  },
  mounted() {
    this.eventsDraw();
    document.addEventListener('resize', this.eventsDraw);
    this.calculateEvents();
  },
  beforeUnmount() {
    // document.removeEventListener('load', this.eventsDraw);
    document.removeEventListener('resize', this.eventsDraw);
  },
  unmounted() {
    // document.removeEventListener('load', this.eventsDraw);
    document.removeEventListener('resize', this.eventsDraw);
  },
  methods: {
    calculateEvents() {
      this.eventsInMonth = JSON.parse(JSON.stringify(this.events)).filter(e => {
        e.times = [...e.times].filter(
            t => {
              return this.moment(t.end).isSameOrAfter(this.moment(this.dateStartMonth).startOf('weeks'))
              && this.moment(t.start).isSameOrBefore(this.moment(this.dateEndMonth).endOf('weeks'))
            }
        );
        console.log(e.times)
        return e;
      }).filter(e => e.times.length);
    }
  },
  computed: {
    dateStartMonth() {
      return this.moment(this.date).startOf('months').format('YYYY-MM-DD');
    },
    dateEndMonth() {
      return this.moment(this.date).endOf('months').format('YYYY-MM-DD');
    },
    dateStartWeekMonth() {
      return this.moment(this.date).startOf('months').startOf('weeks').format('YYYY-MM-DD');
    },
    dateEndWeekMonth() {
      return this.moment(this.date).endOf('months').endOf('weeks').format('YYYY-MM-DD');
    },
    weeksInMonth() {
      let weeks = {};
      for (
          let i = this.moment(this.dateStartWeekMonth);
          this.moment(i).isSameOrBefore(this.dateEndWeekMonth);
          i.add(1, 'days')
      ) {
        const week = i.isoWeek();
        if (weeks[week]) {
          // update
          weeks[week].number = week
          weeks[week].dates.push(i.format('YYYY-MM-DD'));
        } else {
          // create
          weeks[week] = {
            number: week,
            dates: [i.format('YYYY-MM-DD')],
          };
        }
      }
      return _.orderBy(weeks, i => i.dates);
    },
    daysInMonth() {
      let days = [];
      for (
          let i = this.moment(this.dateStartWeekMonth);
          this.moment(i).isSameOrBefore(this.dateEndWeekMonth);
          i.add(1, 'days')
      ) {
        days.push(i.format('YYYY-MM-DD'));
      }
      return days.sort((a, b) => a - b);
    },
    randomColor() {
      return "#" + ((1 << 24) * Math.random() | 0).toString(16);
    },
  },
  watch: {
    events: function () {
      this.calculateEvents();
    },
    'options.dow': function () {
      this.moment.locale('en', {
        week: {
          dow: 1,
        }
      });
    },
  },
}
</script>

<style scoped>
:deep(.fc-body) {
  overflow: hidden;
}

:deep(.fc-header) {
  overflow: hidden;
}

:deep(.fc-header *),
:deep(.fc-header__cell *),
:deep(.fc-header__week *),
:deep(.fc-groups *) {
  font-size: 12px;
}

:deep(.fc-header__month),
:deep(.fc-header__week-title),
:deep(.fc-header),
:deep(.fc-header__day),
:deep(.fc-body__cell),
:deep(.fc-groups__title),
  /*:deep(.fc-groups__list),*/
:deep(.fc-header__cell),
:deep(.fc-groups__list-item) {
  border: 1px solid #c5c6c7;
}

:deep(.fc-groups__title) {
  min-height: 114px;
}

:deep(.fc-header__week-title) {
  max-height: 33px;
  overflow: hidden;
}

:deep(.fc-groups__list) {
  /*min-width: 200px;*/
  min-height: 33px;
}

:deep(.fc-groups__list-item) {
  min-height: 47px;
}

:deep(.fc-header__cell),
:deep(.fc-body__cell) {
  min-height: 47px;
}

:deep(.fc-event) {
  position: absolute;
}

:deep(.fc-body__cell) {
  transform: translateX(0px);
}

:deep(.fc-weekend) {
  color: crimson;
  font-weight: bold;
  background-color: cornsilk;
}

:deep(.fc-prev-month),
:deep(.fc-next-month) {
  color: lightgray;
}

.fs-11 {
  font-size: 11px;
}
</style>
