import * as moment from 'moment';

export const commonMixin = {
  props: {
    events: {
      type: Array,
      default: () => [],
    },
    date: {
      type: Object,
      default: () => moment(),
    },
    holidays: {
      type: Array,
      default: () => [],
    },
  }
}
